// env config
export const API_CONFIG = {
    API_URL: process.env.REACT_APP_API_HOST,
    // oAuthService: 'oauth2-service/',
    CLIENT_CREDENTIALS_INTERNAL: process.env.REACT_APP_CLIENT_CREDENTIALS_INTERNAL,
    survey: '/survey',
    analytics: '/analytics'
};
// keys for localstorage
export const STORAGE_KEY = {
    ACCESS_TOKEN: 'oldst_accesstoken',
    SIDEBAR_STATE: 'sidebar-state'
};

// WEBFONT config
export const WEBFONT_CONFIG = {
    google: {
        families: [
            'Poppins:300',
            'Poppins:400',
            'Poppins:500',
            'Poppins:600',
            'Poppins:700',
            'Poppins:800',
            'Poppins:900'
        ]
    }
};

export const colorBrandActive = '#0069e6';
export const colorBrandGreen = '#25b862';

export const VALID_ACCESS_CODES = [
    '009003',
    '010965',
    '015983',
    '017643',
    '031273',
    '038700',
    '039093',
    '043792',
    '060171',
    '061714',
    '061714',
    '061944',
    '061944',
    '063203',
    '063203',
    '063458',
    '063458',
    '068636',
    '068636',
    '085547',
    '085547',
    '089252',
    '089252',
    '098509',
    '098509',
    '103715',
    '103715',
    '103747',
    '103747',
    '108012',
    '108012',
    '113024',
    '113024',
    '121983',
    '121983',
    '122274',
    '122274',
    '137948',
    '137948',
    '139346',
    '139346',
    '141783',
    '141783',
    '142260',
    '142260',
    '147212',
    '147212',
    '151460',
    '151460',
    '157057',
    '157057',
    '170654',
    '170654',
    '175749',
    '175749',
    '178828',
    '178828',
    '180796',
    '180796',
    '182064',
    '182064',
    '187270',
    '187270',
    '197458',
    '197458',
    '199633',
    '199633',
    '207117',
    '207117',
    '208007',
    '208007',
    '212524',
    '212524',
    '212899',
    '212899',
    '218700',
    '218700',
    '232314',
    '232314',
    '236311',
    '236311',
    '246838',
    '248113',
    '248500',
    '249077',
    '249575',
    '258194',
    '263997',
    '268592',
    '274369',
    '278528',
    '280914',
    '287640',
    '295651',
    '318474',
    '331213',
    '332023',
    '333716',
    '334824',
    '338092',
    '351163',
    '354869',
    '377940',
    '379383',
    '379757',
    '390878',
    '393141',
    '400011',
    '400570',
    '401645',
    '408119',
    '416807',
    '423695',
    '429682',
    '430454',
    '432942',
    '433822',
    '438804',
    '439617',
    '440113',
    '442087',
    '443013',
    '444047',
    '444352',
    '445536',
    '445846',
    '446359',
    '452922',
    '453053',
    '456426',
    '456591',
    '460369',
    '461283',
    '462175',
    '469589',
    '469813',
    '470120',
    '475568',
    '476500',
    '486061',
    '486898',
    '490425',
    '497508',
    '500056',
    '500994',
    '513275',
    '520529',
    '528811',
    '534386',
    '540957',
    '547518',
    '561382',
    '562619',
    '573418',
    '579483',
    '582983',
    '587632',
    '595991',
    '600654',
    '601182',
    '605109',
    '606577',
    '612688',
    '614657',
    '615103',
    '617344',
    '619038',
    '624285',
    '624852',
    '629976',
    '636242',
    '643365',
    '644749',
    '649548',
    '652707',
    '658398',
    '660732',
    '674229',
    '682191',
    '684182',
    '684277',
    '684614',
    '693387',
    '701447',
    '705389',
    '708517',
    '709202',
    '712749',
    '716488',
    '717034',
    '725760',
    '730588',
    '736815',
    '738015',
    '738165',
    '738572',
    '740462',
    '744042',
    '758764',
    '759746',
    '762326',
    '773319',
    '773908',
    '780489',
    '780842',
    '784063',
    '784238',
    '789021',
    '797890',
    '802356',
    '804132',
    '804915',
    '806404',
    '816808',
    '818570',
    '825560',
    '828748',
    '828748',
    '828992',
    '831401',
    '844194',
    '844679',
    '847441',
    '854684',
    '863251',
    '866094',
    '872001',
    '876829',
    '882600',
    '883582',
    '886793',
    '887457',
    '887800',
    '890176',
    '893830',
    '896951'
];
