import React from 'react';
import { Button as BootstrapButton, ButtonProps } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { ButtonVariant } from '../../../types';
import Typography from '../Typography/Typography';
import './button.scss';

type Props = {
    variant?: ButtonVariant,
    label: string,
    className?: string,
    onClick?: () => void,
    children?: JSX.Element | string,
    iconLeft?: any,
    iconRight?: any,
    isProcessing?: boolean,
    iconRightSvg?: any
}

export default function Button({
    variant = 'primary', label, className = '', onClick, iconLeft: IconLeft, iconRight: IconRight, iconRightSvg, children, isProcessing, ...rest
}: Props & ButtonProps): JSX.Element {
    return (
        <BootstrapButton
            variant={variant || 'primary'}
            className={`button ${className} rounded-pill `}
            onClick={onClick}
            {...rest}>
            {isProcessing && (
                <Spinner
                    animation='border'
                    role='status'
                    variant={/light|link/.test(variant || '') ? 'dark' : 'light'}
                    className='button__spinner'
                    size='sm'/>
            )}
            {IconLeft && (
                <IconLeft className='mr-2' color='#000' size={24}/>
            )}
            <Typography color='white' size={16} weight='bold'>{label}</Typography>
            {children}
            {IconRight && (
                <IconRight className='ml-2'/>
            )}

            {iconRightSvg && (
                <img
                    src={iconRightSvg}
                    height={23}
                    width={23}
                    alt='next-arrow' />
            )}
        </BootstrapButton>
    );
}
