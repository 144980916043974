import { useEffect, useState } from 'react';
import OfflineScreen from 'routes/Offline';

// type Props = {
//     className?: string
// }

export default function CheckInternetConnectionContainer(props: any): JSX.Element {
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(() => {
        setOnline(navigator.onLine);
    }, []);

    // event listeners to update the state
    window.addEventListener('online', () => {
        setOnline(true);
    });

    window.addEventListener('offline', () => {
        setOnline(false);
    });

    // if user is online, return the child component else return a custom component
    if (isOnline) {
        return props.children;
    } else {
        return <OfflineScreen />;
    }
}
