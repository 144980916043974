import { useHistory } from 'react-router-dom';
import OfflineScreen from './OfflineScreen';

export default function OfflineContainer(props: any): JSX.Element {
    // state variable holds the state of the internet connection
    const history = useHistory();

    const refreshPage = () => {
        history.go(0);
    };

    return <OfflineScreen refreshPage={refreshPage}/>;
}
