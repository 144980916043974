import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import AlertSVG from 'assets/images/alert.svg';
import SUCCESSSVG from 'assets/images/circle-check.svg';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { clearFlashMessage } from 'redux/modules/flashNotification';
import './flash-notification.scss';

type Props = {
    className?: string
}

export default function FlashNotification({ className = '' }: Props): JSX.Element {
    const { message, isError, duration } = useAppSelector(({ flashNotification }) => flashNotification);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                dispatch(clearFlashMessage());
            }, duration);
        }
    }, [message, duration, dispatch]);

    return (
        <div className={`flash-notification${message ? ' is-visible' : ''}`} >
            <Alert variant={isError ? 'danger' : 'success'} className='flash-notification__alert animateOpen'>
                <img
                    src={isError ? AlertSVG : SUCCESSSVG}
                    height={24} width={24} alt='alert-svg'/>
                {message}
            </Alert>
        </div>
    );
}
