import PageLoadable from 'components/modules/Loadable/PageLoadable';

const AsyncNotFound = PageLoadable({ loader: () => import('./NotFound') });
const AsyncLogin = PageLoadable({ loader: () => import('./Login') });
// const AsyncDashboard = PageLoadable({ loader: () => import('./Dashboard') });
// const AsyncSignUp = PageLoadable({ loader: () => import('./SignUp') });
// const AsyncForgotPassword = PageLoadable({ loader: () => import('./ForgotPassword') });
const AsyncOtp = PageLoadable({ loader: () => import('./Otp') });
const AsyncSurveyIntro = PageLoadable({ loader: () => import('./SurveyIntro') });
const AsyncSurvey = PageLoadable({ loader: () => import('./Survey') });
const AsyncCompletedSurvey = PageLoadable({ loader: () => import('./CompletedSurvey') });

// const AsyncStaticScreen = PageLoadable({ loader: () => import('./StaticScreen') });
const AsyncConsent = PageLoadable({ loader: () => import('./Consent') });
const AsyncAccessCode = PageLoadable({ loader: () => import('./AccessCode') });
const AsyncOffline = PageLoadable({ loader: () => import('./Offline') });
const AsyncSplash = PageLoadable({ loader: () => import('./Splash') });
const AsyncOnboarding = PageLoadable({ loader: () => import('./Onboarding') });
// ROUTE IMPORT CODE GENERATOR INDICATOR DO NOT DELETE

const routes = {
    ONBOARDING: {
        component: AsyncOnboarding,
        path: '/onboarding',
        authedRoute: false
    },
    LOGIN: {
        component: AsyncLogin,
        path: '/login',
        authedRoute: false
    },
    // DASHBOARD: {
    //     component: AsyncDashboard,
    //     path: '/dashboard',
    //     authedRoute: true
    // },
    // SIGN_UP: {
    //     component: AsyncSignUp,
    //     path: '/sign-up',
    //     authedRoute: false
    // },
    // FORGOT_PASSWORD: {
    //     component: AsyncForgotPassword,
    //     path: '/forgot-password',
    //     authedRoute: false
    // },
    Otp: {
        component: AsyncOtp,
        path: '/otp',
        authedRoute: false
    },
    SURVEY_INTRO: {
        component: AsyncSurveyIntro,
        path: '/survey/intro',
        authedRoute: false
    },
    COMPLETED_SURVEY: {
        component: AsyncCompletedSurvey,
        path: '/survey/completed',
        authedRoute: false
    },
    SURVEY: {
        component: AsyncSurvey,
        path: '/survey',
        authedRoute: false
    },
    // STATIC_SCREEN: {
    //     component: AsyncStaticScreen,
    //     path: '/static',
    //     authedRoute: false
    // },
    CONSENT: {
        component: AsyncConsent,
        path: '/consent',
        authedRoute: false
    },
    ACCESS_CODE: {
        component: AsyncAccessCode,
        path: '/access-code',
        authedRoute: false
    },
    OFFLINE: {
        component: AsyncOffline,
        path: '/offline',
        authedRoute: false
    },
    SPLASH: {
        component: AsyncSplash,
        path: '/',
        authedRoute: false
    },
    // ROUTE ENTRY CODE GENERATOR INDICATOR DO NOT DELETE
    NOT_FOUND: {
        component: AsyncNotFound,
        path: '*',
        authedRoute: false
    }
};

export default routes;
